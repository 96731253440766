<template>
  <component :is="component" :class="classes" :style="style">
    <slot />
  </component>
</template>

<script lang="ts" setup>
interface GridItemProps {
  sm?: number | string;
  md?: number | string;
  l?: number | string;
  xl?: number | string;
  xxl?: number | string;
  offsetSm?: number | string;
  offsetMd?: number | string;
  offsetL?: number | string;
  offsetXl?: number | string;
  offsetXxl?: number | string;
  component?: keyof HTMLElementTagNameMap;
  order?: number | string;
}

const props = withDefaults(defineProps<GridItemProps>(), {
  component: 'div',
  l: 0,
  md: 0,
  offsetL: 0,
  offsetMd: 0,
  offsetSm: 0,
  offsetXl: 0,
  offsetXxl: 0,
  order: undefined,
  sm: 0,
  xl: 0,
  xxl: 0,
});

const classes = computed(() => {
  const gridItemClasses: string[] = [];

  for (const size in props) {
    if (['component', 'order'].includes(size)) continue;

    const isOffset = size.startsWith('offset');

    const value = props[size as keyof GridItemProps];

    if (value && !isOffset) gridItemClasses.push(`grid-cell-${size}-${value}`);
    if (value && isOffset) {
      const offsetSize = size.replace('offset', '').toLowerCase();
      gridItemClasses.push(`grid-cell-offset-${offsetSize}-${value}`);
    }
  }

  return gridItemClasses;
});

const style = computed(() => {
  if (!props.order) return undefined;

  return { order: props.order };
});
</script>
